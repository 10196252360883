import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import apiClient from "../../_helper/http-common";
import Select from "react-select";
import Multiselect from 'multiselect-react-dropdown';
import swal from "sweetalert";
import group from "../../Components/assets/Group.png";
import "../../Components/assets/style.css";
import { COUNTRIES } from "../../countries"
import { TagsInput } from "react-tag-input-component";

function Appointment({ isOpen, handleIsOpen }) {
  const [medicines, setMedicines] = useState([]);
  const [MedArray, setMedArray] = useState([]);
  const [formMeds, setFormMeds] = useState([]);
  const [tags, setTags] = React.useState([]);
  const { isLoading: isLoadingTutorials, refetch: getAllMedicines } = useQuery(
    "query-medicines",
    async () => {
      return await apiClient.get("/medicine/getAllMedicine");
    },
    {
      enabled: false,
      onSuccess: (res) => {
        setMedicines(res.data);
      },
      onError: (err) => {
        console.log("🚀 ~ file: Appointment.js:27 ~ Appointment ~ err", err);
      },
    }
  );

  useEffect(() => {
    getAllMedicines();
  }, []);

  const {
    register: appointmentForm,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      fullName: "",
      dob: "",
      number: "",
      city: '',
      country: '',
      streetAddress: '',
      medication: '',
      zipCode: ""
    },
  });

  const onSelect = (selectedList, selectedItem) => {
    if (selectedItem.name == "Other") {
      setIsOther(true)
      selectedList = selectedList.filter((item) => { return item.name == "Other" });
      setFormMeds([]);
      console.log(formMeds)
    } else {
      setIsOther(false)
      selectedList = selectedList.filter((item) => { return item.name !== "Other" });
      formMeds.push(selectedItem.name)
      setValue('medication', formMeds)
    }
    setMedArray(selectedList)

  }
  const onRemove = (selectedList, removedItem) => {
    const index = formMeds.findIndex(med => med === removedItem.name);
    console.log(index)
    console.log(formMeds.splice(index, 1))
    if (index > -1) {
      setFormMeds(formMeds.splice(index, 1));
    }
    setValue('medication', formMeds)
  }
  const otherMed = (tag) => {
    setFormMeds(tag)
    setValue('medication', tag)
  }

  const onSubmit = (data) => {
    apiClient
      .post("/user/createUser", data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          handleIsOpen(false);
          reset({
            fullName: "",
            dob: "",
            number: "",
            city: '',
            country: '',
            streetAddress: '',
            medication: "",
            zipCode: ''
          });
          swal({
            icon: "success",
            title: "Congrats",
            text: "Your Appointment is booked successfully",
            timer: 1000,
            buttons: false,
          });
          window.location.href = "https://worldcaremd.navigohealth.com/"
        } else {
          swal({
            icon: "error",
            title: "Oops",
            text: res.data.message,
            timer: 1000,
            buttons: false,
          });
        }
      })
      .catch((e) => console.log(e));
  };
  const [takeMedication, setTakeMedication] = useState("no");
  const [isOther, setIsOther] = useState(false);
  const onChangeTakeMedication = (e) => {
    setTakeMedication(e.target.value);
  };

  return (
    <ReactModal preventScroll={true} onRequestClose={handleIsOpen} isOpen={isOpen}>
      <div className="container">
        <div className="row">
          <div className="col-sm appoint-img">
            <img className="img-fluid" src={group} alt="" />
          </div>
          <div className="col-sm appoint-fields">
            <h4>Welcome to WorldCareMD</h4>
            <p>Please fill your information below.</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label htmlFor="fullName" className="form-label">
                  Full Name*
                </label>
                <input
                  {...appointmentForm("fullName", { required: true })}
                  className="form-control"
                />
                {errors.fullName && <span className="invalid-feedback">FullName is required</span>}
              </div>

              <div class="form-group">
                <label htmlFor="dob" className="form-label">
                  Date of Birth*
                </label>
                <input
                  {...appointmentForm("dob", { required: true })}
                  type="date"
                  className="form-control"
                />
                {errors.dob && <span className="invalid-feedback">Date of Birth is required</span>}
              </div>
              <div className="form-group">
                <label htmlFor="dob" className="form-label">
                  Address*
                </label>
                <input
                  {...appointmentForm("streetAddress", { required: true })}
                  className="form-control"
                />
                {errors.streetAddress && <span className="invalid-feedback">Address is required</span>}
              </div>
              <div className="form-group">
                <label htmlFor="dob" className="form-label">
                  City*
                </label>
                <input
                  {...appointmentForm("city", { required: true })}
                  className="form-control"
                />
                {errors.city && <span className="invalid-feedback">City is required</span>}
              </div>
              <div className="form-group">
                <label htmlFor="dob" className="form-label">
                  Country*
                </label>
                <Select
                  {...appointmentForm("country", { required: true })}
                  onChange={(e) =>
                    setValue("country", e.code)
                  }
                  options={COUNTRIES}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.name}
                />
                {errors.country && <span className="invalid-feedback">Country is required</span>}
              </div>
              <div class="form-group">
                <label htmlFor="dob" class="form-label">
                  Mobile/Phone No. *
                </label>
                <input
                  {...appointmentForm("number", { required: true })}
                  type="tel"
                  className="form-control"
                />
                {errors.number && (
                  <span className="invalid-feedback">Mobile/Phone No. is required</span>
                )}
              </div>
              <div class="form-group">
                <label htmlFor="dob" class="form-label">
                  Zip / postcode *
                </label>
                <input
                  {...appointmentForm("zipCode", { required: true })}
                  type="text"
                  className="form-control"
                />
                {errors.zipCode && (
                  <span className="invalid-feedback">Zip / postcode. is required</span>
                )}
              </div>
              <div class="form-group">
                <label htmlFor="takeMedication" class="form-label">
                  Are you taking any medication?
                </label>
                <div>
                  <input
                    type="radio"
                    value="yes"
                    checked={takeMedication === "yes"}
                    onChange={onChangeTakeMedication}
                    name="takeMedication"
                  />
                  Yes &nbsp; &nbsp; &nbsp;
                  <input
                    type="radio"
                    value="no"
                    checked={takeMedication === "no"}
                    onChange={onChangeTakeMedication}
                    name="takeMedication"
                  />
                  No
                </div>
              </div>

              {takeMedication === "yes" && (
                <>
                  <label htmlFor="medication" className="form-label">
                    Prescriptions*
                  </label>
                  <Multiselect
                    options={medicines.data}
                    onSelect={onSelect}
                    displayValue="name"
                    showCheckbox={true}
                    onRemove={onRemove}
                    selectedValues={MedArray}
                  />
                  <div className="form-group">

                    {/* <Select
                      onChange={(e) =>
                        e.name === "Other" ? setIsOther(true) : setValue("medication", e.name)
                      }
                      options={[{ name: "Other" }, ...medicines.data]}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.name}
                    /> */}

                    {errors.address && (
                      <span className="invalid-feedback">Prescriptions is required</span>
                    )}
                  </div>
                  {isOther && (
                    <>

                      <label htmlFor="medication" className="form-label">
                        Please enter other medication*
                      </label>
                      {/* <input {...appointmentForm("medication")} className="form-control" /> */}
                      <TagsInput
                        value={formMeds}
                        onChange={otherMed}
                        name="test"
                        placeHolder="press enter to add new"
                      />

                    </>
                  )}
                </>
              )}
              <div className="modal-buttons mt-2">
                <button onClick={handleIsOpen} className="btn btn-x">
                  Close
                </button>
                <button className="btn btn-submit" type="submit" disabled={!isDirty || !isValid}>
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default Appointment;
