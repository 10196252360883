import React from "react";
import prescription from "../assets/Prescription.png";
const Privacy = () => {
  return (
    <div>
      <div className="privacy">
        <div className="container-fluid p-0 ">
          <div className="policy-heading">
            <h2>Prescription Policy</h2>
            <p>Last updated: June 2022</p>
          </div>
        </div>
        <div className="container  pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 ">
              <div className="privacy-content mt-4">
                <div className="personal-data gap-3 d-flex mt-lg-5">
                  <span className="arrow-right">
                    <i class="fa-solid fa-circle-arrow-right"></i>
                  </span>
                  <h3 className="text-blue">What personal data we collect and why we collect it</h3>
                </div>
                <p className="privacy-para">
                  During a telephone or video consultation, WorldCareMD
                  physicians have access to a large variety of medications that
                  can be provided as necessary. An email consultation will not
                  result in the prescription of any drugs. Physician-prescribed
                  drugs are e-prescribed to a member's preferred pharmacy for
                  local pick-up or shipped to a member's home or office via mail
                  order pharmacy.
                </p>
                <p className="privacy-para">
                  If a doctor does prescribe medication, the supply will
                  normally be limited to thirty days. Chronic illness patients
                  should see their primary care physician (PCP) or other
                  specialists.
                </p>
                <p className="privacy-para">
                  If a doctor does prescribe medication, the supply will
                  normally be limited to thirty days. Chronic illness patients
                  should see their primary care physician (PCP) or other
                  specialists.
                </p>
                <p className="privacy-para mt-4">
                  As part of a medical consultation, WorldCareMD physicians will
                  not prescribe prescriptions for substances that are restricted
                  by the DEA or that have the potential for harm, misuse, or
                  addiction. Visit
                </p>
                <p className="privacy-para">
                  http://www.deadiversion.usdoj.gov/schedules/index.html for a
                  current list of DEA controlled substances.
                </p>
                <p className="privacy-para ">
                  All prescriptions are filled according to WorldCareMD's
                  prescription policy and at the consulting physician's
                  discretion.
                </p>

                <hr />
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="prescription-img mt-lg-4">
                <img src={prescription} className="w-100" alt="" />
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-8 pe-3 privacy-content">
              <div className="d-flex  gap-3 my-4">
                <span className="arrow-right">
                  <i class="fa-solid fa-circle-arrow-right"></i>
                </span>
                <h3 className="text-blue">
                  Why your WorldCareMD doc may NOT call in antibiotics for a
                  runny nose or a cough.
                </h3>
              </div>
              <p className="privacy-para">
                The majority of cold-like symptoms are caused by viral
                infections. Sore throats, coughs, and stuffy noses usually get
                better with time, supportive care, relaxation, and fluids.
              </p>
              <p className="privacy-para">
                These things may be suggested by your consultation doctor as a
                first step toward feeling better. Antibiotic overuse is quickly
                becoming a worldwide health concern, and one that we at
                WorldCareMD take seriously.
              </p>
              <p className="privacy-para">
                Furthermore, if an antibiotic is required, your WorldCareMD
                physician knows which medications are appropriate for which
                diseases.
              </p>
              <p className="privacy-para">
                The CDC* and associated medical associations are quite clear
                about which medications should be used as first-line therapy and
                which medications should be utilized for individuals with
                allergies or sensitivities.
              </p>
              <p className="privacy-para">
                http://www.deadiversion.usdoj.gov/schedules/index.html for a
                current list of DEA controlled substances.
              </p>
              <p className="privacy-para">
                Visit the CDC.GOV website for more information on the importance
                of antibiotic stewardship.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
