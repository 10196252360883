import React from "react";

import doctor from "../assets/doctor.png";
import provider from "../assets/provider.png";

const About = () => {
  return (
    <div>
      <div className="about pb-5" id="about">
        <div className="container-flui p-0">
          <div className="about-banner">
            <div className="container">
              <h1>
                About <span className=""> WorldCareMD </span>{" "}
              </h1>
              <p className="image-aboutus-para">
                We value the concept of trust.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-12 px-md-0 px-3">
              <div className="row justify-content-center">
                <div className="col-12 col-xxl-8">
                  <div className="about-content ">
                    <h1 className="text-center">WorldCareMD</h1>
                    <p className="about-para mt-3">
                      We value the concept of trust.
                    </p>
                    <p className="about-para">
                      WorldCareMD's knowledgeable staff understands that you are
                      entrusting your health, the health of your family, and the
                      health of your employees to us, and we take that
                      responsibility very seriously.
                    </p>
                    <p className="about-para">
                      "Why WorldCareMD?" is an easy question to answer with over
                      a decade of expertise and a leading network of
                      telemedicine-trained physicians.
                    </p>
                    <p className="about-para">
                      Our response is straightforward: price and quality
                      converge.
                    </p>
                    <p className="about-para">
                      We provide members with individual portals where records
                      are safe and secure; initiating a medical consultation is
                      simple; helpful decision-making and wellness tools are
                      available; and useful information can be found in our A to
                      Z library. WorldCareMD is a trusted leader in the
                      telehealth industry, making it simple and easy.
                    </p>
                    <p className="about-para">
                      Care at your fingertips: Anywhere in the world, from your
                      home, office, or travels
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div className="py-lg-5">
          <div className="medical-info my-5">
            <h1 className="text-center">Medical Director</h1>
            <p className="about-para mt-3">
              Dr. Joseph Burrascano is a leading medical authority in his field.
              During his 25 years as an internist in East Hampton, N.Y., he
              treated more than 11,000 patients from 46 states and 16 countries.
            </p>
            <p className="about-para">
              He is author of the ILADS Lyme Diagnosis and Treatment Guidelines
              in addition to publishing numerous papers in medical journals. He
              is currently heading an ongoing research project developing a
              universal Lyme and associated diseases database.
            </p>
            <p className="about-para mt-4">
              He has been in and on practically every form of media, advised the
              CDC and NIH, testified before the US Senate, an armed services
              joint subcommittee, and different governor's councils, and has
              over three decades of clinical expertise and research in this
              subject.
            </p>
            <p className="about-para mt-4">
              Dr. Burrascano also serves as medical director for Advanced
              Research Corporation in St. Petersburg, Fla. His diagnostic skills
              were recently featured on the Discovery Channel Series Medical
              Mysteries in “The Laura Zeller Story.”.
            </p>
          </div>
          </div>
        </div>

        <div className="container-fluid p-0">
          <div className="provider">
            <div className="container">
              <div className="row">

                <div className="col-lg-12 px-md-0 px-3">
                  <div className="row p-0 g-0 justify-content-center">
                    <div className="col-12 col-xxl-8">
                      <div className="provider-content">
                        <h1 className="text-center">Our Providers</h1>
                        <p className="about-para mt-3">
                          Creating one of the country's largest networks of
                          telemedicine providers is no easy undertaking.
                          However, WorldCareMD has done just that, providing
                          easy treatment wherever.
                        </p>
                        <p className="about-para">
                          Our board-certified physicians are available at any
                          time of day or night to diagnose, recommend treatment,
                          and prescribe drugs while you are in the comfort of
                          your own home, office, or business trip destination.
                        </p>
                        <p className="about-para">
                          You may rely on the expertise of the medical staff at
                          WorldCareMD.
                        </p>
                        <p className="about-para mt-2">Provider Information:</p>
                        <p className="about-para">
                          Each physician meets the best qualifications. Every
                          two years, a national third-party credentialing
                          organization conducts a thorough assessment of medical
                          licensing, training, education, work, and malpractice
                          history in compliance with the National Committee for
                          Quality Assurance (NCQA) and the Utilization Review
                          Accreditation Commission.
                        </p>
                        <p className="about-para mt-2">
                          Allergies, arthritic pain, flu symptoms, sinusitis,
                          bronchitis, gastroenteritis, minor burns, respiratory
                          infection, sore throat, sprain or strain, urinary
                          tract infection, and many other non-emergency medical
                          diseases can be diagnosed and treated by WorldCareMD
                          experts. ​
                        </p>
                        <p className="about-para mt-2">
                          You may feel certain that each physician is officially
                          licensed in your state, board-certified, and validated
                          by the National Physician Data Base and the American
                          Medical Association, with an average of 15 years of
                          internal medicine, family practice, and pediatrics
                          expertise.
                        </p>
                        <p className="about-para mt-2">
                          WorldCareMD provides rigorous telemedicine training to
                          ensure that each physician can deliver the
                          high-quality treatment you deserve.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
