import React, { useEffect, useState } from "react";
import arrow from "../../Components/assets/arrow.png";
import banner1 from "../../Components/assets/banner1.png";
import banner2 from "../../Components/assets/banner2.png";
import banner3 from "../../Components/assets/banner3.png";
import register from "../../Components/assets/register.svg";
import check from "../../Components/assets/check.svg";
import request from "../../Components/assets/request.svg";
import talk from "../../Components/assets/talk.svg";
import allergies from "../../Components/assets/allergies.svg";
import cold from "../../Components/assets/cold.svg";
import pharyngitis from "../../Components/assets/pharyngitis.svg";
import infection from "../../Components/assets/skin-infection.svg";
import gastroenteritis from "../../Components/assets/gastroenteritis.svg";
import ear from "../../Components/assets/ear-infection.svg";
import pink from "../../Components/assets/pink-eye.svg";
import respiratory from "../../Components/assets/respiratory-Infections.svg";
import sinusitis from "../../Components/assets/sinusitis.svg";
import sprain from "../../Components/assets/sprain-and-strains.svg";
import urinary from "../../Components/assets/urinary-tract-infection.svg";
import consulting from "../../Components/assets/consulting-for-International.svg";
import depression from "../../Components/assets/Depression&Anxiety.svg";
import work from "../../Components/assets/Work-related-stress.svg";
import alcohol from "../../Components/assets/Alcohol-Drug-problems.svg";
import grief from "../../Components/assets/Grief&loss.svg";
import eating from "../../Components/assets/Eating-Disorders.svg";
import stress from "../../Components/assets/Stress&Pain-management.svg";
import marriage from "../../Components/assets/Marriage-relationship-problems.svg";
import personal from "../../Components/assets/Personal-Distress.svg";
import Eldercare from "../../Components/assets/Eldercare-childcare-issues.svg";
import major from "../../Components/assets/Major-life-event.svg";
import concerns from "../../Components/assets/Health-care- concerns.svg";
import much from "../../Components/assets/MUCH MORE.svg";
import phone from "../../Components/assets/phone.svg";
import mail from "../../Components/assets/mail.svg";
import Appointment from "./Appointment";
import Contact from "./Contact";

const Home = () => {
  const [isOpen, setIsOpen] = useState();

  const handleIsOpen = (value) =>  {
      setIsOpen(!isOpen);
  }
 

  return (
    <div>
      <Appointment handleIsOpen={handleIsOpen} isOpen={isOpen}/>
      <section className="first-banner pb-5">
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-6 mt-5 pt-5 px-5">
              <div className="banner1-content mt-lg-5 pt-lg-5">
                <h1>
                  Take charge of your health now with{" "}
                  <span className="blue-text">WorldCareMD</span>
                </h1>
                <p className="mt-3">HealthCare reimagined</p>
                <div className="banner1-btn mt-4 ">
                  <button onClick={handleIsOpen} className="btn appointment-btn">
                    Book an Appointment
                  </button>
                  {/* <a href="javascript:void(0)" className="works-btn">
                    how it Works
                    <img className="ms-2" src={arrow} alt="" />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5 pt-5 px-5">
              <div className="banner1-img">
                <img src={banner1} className="w-100" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="second-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner2-image">
                <img src={banner2} className="w-100" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner2-content mt-lg-5 pt-lg-5">
                <p className="story">Story</p>
                <h1>
                  <span className="text-green">About </span>WorldCareMD
                </h1>
                <p>
                  WorldCareMD member's benefit from one of the country's
                  largest networks of telemedicine physicians.
                </p>
                <p>
                  Over the phone or via secure bi-directional video and email,
                  our national network of board certified, licensed Internal
                  Medicine and Emergency Room physicians diagnose ailments,
                  recommend therapy, and prescribe drugs for its members.
                </p>
                <a href="javascript:void(0)" className="btn read-more-btn">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="third-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 px-5">
              <div className="banner3-content mt-4">
                <h1>
                  <h1>
                    <span className="text-green">Why </span>WorldCareMD
                  </h1>
                </h1>

                <div className="company-info1 mt-5">
                  <div className="info">
                    <img className="me-3" src={check} alt="" />
                    <span>97% Member Satisfaction</span>
                  </div>
                </div>
                <div className="company-info1">
                  <div className="info">
                    <img className="me-3" src={check} alt="" />
                    <span>92% Of Medical Issues Resolved</span>
                  </div>
                </div>
                <div className="company-info1">
                  <div className="info">
                    <img className="me-3" src={check} alt="" />
                    <span>98% Client Retention</span>
                  </div>
                </div>
                <div className="company-info1">
                  <div className="info">
                    <img className="me-3" src={check} alt="" />
                    <span>98% Of Members Recommend </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-5">
              <div className="banner3-image mt-5 pt-3">
                <img src={banner3} className="w-100" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fourth mt-5">
        <div className="container">
          <p className="text-center process">process</p>
          <h1 className="text-center">
            How to Get <span className="text-green">Started</span>
          </h1>
          <div className="start-map-content mt-4 ">
            <div className="row">
              <div className="col-lg-4">
                <div className="register text-center mt-lg-0 mt-3">
                  <img src={register} alt="" />
                </div>
                <div className="register-content mt-3">
                  <h3 className="text-center">1. Register</h3>
                  <p className="text-center">
                    Setup your profile and complete your electronic health
                    record, then login to your account anytime 24/7 to request a
                    consult.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="request text-center mt-lg-0 mt-3">
                  <img src={request} alt="" />
                </div>
                <div className="request-content mt-3">
                  <h3 className="text-center">2. Request a Consult</h3>
                  <p className="text-center w-75">
                    Consult with one of our board-certified physicians via
                    secure bi-directional video or phone day or night.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="talk text-center mt-lg-0 mt-3">
                  <img src={talk} alt="" />
                </div>
                <div className="talk-content mt-3">
                  <h3 className="text-center">3. Talk with Doctor</h3>
                  <p className="text-center w-75">
                    Receive diagnosis and treatment by our physicians to get
                    quality care and peace of mind where ever you are day or
                    night.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr className="mt-5" />
      <section className="fifth" id="services">
        <div className="container">
          <p className="non-emergency text-center">Non-Emergency</p>
          <h1 className="text-center">We Treat a Broad Range of</h1>
          <h1 className="text-center text-green">
            Non-Emergency Medical Conditions
          </h1>
          <p className="health text-center text-lg-start">General Health</p>
          <div className="health-problems">
            <div className="row">
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={allergies} className="me-3" alt="" />
                    <span>Allergies</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={cold} className="me-3" alt="" />
                    <span>Cold & Flu</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={pharyngitis} className="me-3" alt="" />
                    <span>Pharyngitis</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={infection} className="me-3" alt="" />
                    <span>Skin Infections</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={gastroenteritis} className="me-3" alt="" />
                    <span>Gastroenteritis</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={ear} className="me-3" alt="" />
                    <span>Ear Infection</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={pink} className="me-3" alt="" />
                    <span>Pink Eye</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={respiratory} className="me-3" alt="" />
                    <span>Respiratory Infections</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={sinusitis} className="me-3" alt="" />
                    <span>Sinusitis</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={sprain} className="me-3" alt="" />
                    <span>Sprains and Strains</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={urinary} className="me-3" alt="" />
                    <span>Urinary tract Infection</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={consulting} className="me-3" alt="" />
                    <span>Consulting for International</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="health text-center text-lg-start mt-5">
            Behavioral Health
          </p>
          <div className="health-problems">
            <div className="row">
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={depression} className="me-3" alt="" />
                    <span>Depression</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={work} className="me-3" alt="" />
                    <span>Grief and Loss</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={alcohol} className="me-3" alt="" />
                    <span>Drug/Alcohol Issues</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={grief} className="me-3" alt="" />
                    <span>Marriage Issues</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={eating} className="me-3" alt="" />
                    <span>Eating Disorders</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={stress} className="me-3" alt="" />
                    <span>Smoking Cessation</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={marriage} className="me-3" alt="" />
                    <span>Parenting Issues</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={personal} className="me-3" alt="" />
                    <span>Anxiety</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={Eldercare} className="me-3" alt="" />
                    <span>Childcare, Eldercare</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={major} className="me-3" alt="" />
                    <span>Obsessive Compulsive Disorder</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={concerns} className="me-3" alt="" />
                    <span>ADHD</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="healthbox-border">
                  <div className="health-box">
                    <img src={much} className="me-3" alt="" />
                    <span>Gambling Addiction</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="form" id="contact">
        <div className="container">
          <p className="message text-center">Message</p>
          <h1 className="text-center">Contact Us</h1>
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="row mt-5">
                <div className="col-lg-6">
                  <div className="chat-box">
                    <img src={phone} alt="" />
                    <div>
                      <p>Monday-Sunday: 24/7</p>
                      <p>(877) 2-MYDOCTOR</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="chat-box mt-lg-0 mt-3">
                    <img src={mail} alt="" />
                    <div>
                      <p>We will reply to you as early as possible:</p>
                      <p>
                        <a
                          className="text-white text-decoration-none"
                          href="javascript:void(0)"
                        >
                          support@worldcaremd.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Contact />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
