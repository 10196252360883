import React from "react";
import logo from "../assets/logo.png";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";
import linkedin from "../assets/linkedin.svg";
import twitter from "../assets/twitter.svg";
import apps from "../assets/app_icons.png";
import { useNavigate } from "react-router-dom";
var bootstrap;


const Footer = () => {    
  const navigate = useNavigate();

  return (
    <div>
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <div class="footer-logo text-lg-start text-center">
                <img src={logo} height={50} alt="" />
              </div>
              <ul class="address">
                <li class="fw-700">Address</li>
                <li class="w-75">
                777 South Flagler Drive
                Suite 800 - West Tower
                West Palm Beach, Florida 33401
                </li>
                <li>

                  <img src={apps} alt="" />

                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-6">
              <div class="heading mt-5 mt-lg-0">Product</div>
              <ul>
                <li>
                  <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Coming Soon">Mobile App</a>
                </li>
                <li>
                  <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Coming Soon">Telehealth</a>
                </li>
                <li>
                  {" "}
                  <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Coming Soon">Text messaging</a>
                </li>
                <li>
                  {" "}
                  <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Coming Soon">Patient payments</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-6">
              <div class="heading mt-5 mt-lg-0">Company</div>
              <ul>
                <li>

                  <a href="#about" onClick={() => navigate("/about")}>About Us</a>
                </li>
                <li>
                  <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Coming Soon">Blog</a>
                </li>
                <li>
                  <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Coming Soon">Events</a>
                </li>
                <li>
                  <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Coming Soon">Reviews</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-6">
              <div class="heading mt-5 mt-lg-0">Quick Links</div>
              <ul>
                <li>
                  <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Coming Soon">Employers</a>
                </li>
                <li>
                  <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Coming Soon">Health Plans</a>
                </li>
                <li>
                  <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Coming Soon">Consultants</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div class="sub-footer">
        <div class="container">
          <div class="sub-footer-content">
            <div class="social">
              <a href="javascript:void(0)" class="me-1">
                <img src={facebook} alt="" />
              </a>
              <a href="javascript:void(0)" class="me-1">
                <img src={instagram} alt="" />
              </a>
              <a href="javascript:void(0)" class="me-1">
                <img src={linkedin} alt="" />
              </a>
              <a href="javascript:void(0)" class="me-1">
                <img src={twitter} alt="" />
              </a>
            </div>
            <div class="copyright mt-3 mt-lg-0">
              <p class="mb-0">
                © <span class="fw-700">2022</span> All Rights Reserved.
              </p>
            </div>
            <div class="links mt-3 mt-lg-0">
              <a href="javascript:void(0)" class="me-4">
                Privacy Policy
              </a>
              <a href="javascript:void(0)">Terms and Conditions </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;


const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList]
