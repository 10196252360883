import React, { useState, useEffect } from "react";
import logo from "../../Components/assets/logo.png";
import { NavLink, useNavigate } from "react-router-dom";


const Header = () => {
  const navigate = useNavigate();

  const toggle = () => {
    var element = document.getElementById("navbarSupportedContent");
    element.classList.remove("show");
  }

  const [scrollActive, setScrollActive] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollActive(window.scrollY > 20);
    });
  }, []);

  return (
    <>
      <nav className={"navbar navbar-expand-lg transition-all " +
          (scrollActive ? "fixed-top bg-white shadow mt-lg-0" : "fixed-top mt-lg-2")}>

        <div class="container">
          <NavLink className="navbar-brand" to="/">
            <img src={logo} className="logo-img" alt="" />
          </NavLink>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="fa fa-bars text-primary"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a 
                  className="nav-link"
                  aria-current="page"
                  href="#services"
                  onClick={() =>{ navigate("/"); toggle()}} 
                >
                  Service
                </a>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" onClick={toggle} activeClassName="active" to="/about">
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" onClick={toggle} activeClassName="active" to="/privacy">
                  Prescription Policy
                </NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() =>{ navigate("/"); toggle()}} href="#contact">
                  Contact
                </a>
              </li>
            </ul>

            <div class="header-btn">
              <a href="https://worldcaremd.navigohealth.com/" class="btn start-btn">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
