import React from 'react'
import { useForm } from "react-hook-form";
import apiClient from "../../_helper/http-common";
import swal from "sweetalert";

function Contact() {
    const {
      register: contactForm,
      setValue,
      handleSubmit,
      reset,
      formState: { errors, isDirty, isValid },
    } = useForm({
      defaultValues: {
        subject: "",
        message: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
    });
      const onSubmit = (data) => {
        apiClient
          .post("/contactUs/sendMail", data)
          .then((res) => {
            if (res.data.statusCode === 200) {
              reset({
                subject: "",
                message: "",
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
              });
              swal({
                icon: "success",
                title: "Thankyou",
                text: "Customer Support will contact you soon!",
                timer: 1000,
                buttons: false,
              });
            } else {
              swal({
                icon: "error",
                title: "Oops",
                text: res.data.message,
                timer: 1000,
                buttons: false,
              });
            }
          })
          .catch((e) => console.log(e));
      };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <div className="row mt-5">
      <div className="col-lg-6">
        <div className="name-input">
          <label>First Name *</label>
                
          <input type="text" {...contactForm("firstName", { required: true })} />
        </div>
      </div>
      <div className="col-lg-6">
        <div className="name-input">
          <label>Last Name *</label>
          <input type="text" {...contactForm("lastName", { required: true })} />
        </div>
      </div>
      <div className="col-lg-6">
        <div className="email-input">
          <label>Email *</label>
          <input type="text" {...contactForm("email", { required: true })}/>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="phone-input">
          <label>Phone *</label>
          <input type="text" {...contactForm("phone", { required: true })} />
        </div>
      </div>
    </div>
    <div className="col-lg-12">
        <div className="email-input">
          <label>Subject *</label>
          <input type="text" {...contactForm("subject", { required: true })}/>
        </div>
      </div>
    <div className="message-box">
      <label>Message *</label>
      <textarea rows="10" {...contactForm("message", { required: true })}></textarea>
    </div>
    <button type='submit' 
              disabled={!isDirty || !isValid} 
              className="btn submit-btn mt-3">
      Submit
    </button>
  </form>
  )
}

export default Contact